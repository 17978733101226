import React, { useEffect, useState } from 'react';
import './App.css';
import { DDGlobal } from './react-app-env';
import Caver from 'caver-js';
import { BalanceABI, ContractAddresses } from './contract';

export const klaytn = (window as DDGlobal).klaytn

function App() {
  const [ connectEnabled, setConnectEnable ] = useState(true)
  const [ verifyEnabled, setVerifyEnable ] = useState(false)
  const [ connectButtonText, setConnectButtonText ] = useState('지갑 연결')
  const [ verifyButtonText, setVerifyButtonText ] = useState('인증하기')
  const [ connectClassNames, setConnectClassNames ] = useState('connect-button')

  const queryParams = new URLSearchParams(window.location.search)
  const code = queryParams.get('code')

  const activateKaikas = async () => {
    if (!klaytn) {
      setTimeout(() => {
        alert('카이카스 지갑을 설치 후 활성화해주세요.')
      }, 500)
      return
    }

    try {
      await klaytn.enable()
      await kaikasActivated()
    } catch (e) {
    }
  }

  const kaikasActivated = async () => {
    if (!klaytn?.selectedAddress) return

    klaytn.on('accountsChanged', (accounts) => {
      setConnectButtonText(accounts[0])
    })
    klaytn.on('networkChanged', function () {
      balanceQuery().finally()
    })

    setConnectEnable(false)
    setConnectClassNames('connect-button activate')
    setConnectButtonText(klaytn.selectedAddress)
  }

  const verify = async () => {
    if (!klaytn?.isKaikas)
      return

    if (!code) {
      setTimeout(() => {
        alert('유저를 식별할 수 없습니다. 디스코드에서 인증 버튼을 눌러 접근해주세요.')
      }, 500)
      return
    }

    if (klaytn.networkVersion !== 8217) {
      setTimeout(() => {
        alert('카이카스 지갑이 메인넷에 연결되지 않았습니다.')
      }, 500)
      return
    }

    try {
      const response = await fetch('/api/callback', {
        headers: { 'content-type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({
          code: code,
          address: klaytn.selectedAddress
        })
      })

      const result: { success: boolean } = await response.json()

      setVerifyEnable(false)
      if (result.success) {
        setVerifyButtonText('✔️인증되었습니다')
      } else {
        setVerifyButtonText('❗️현재 홀더가 아닙니다')
      }

    } catch (e) {
      setTimeout(() => {
        alert('유저를 식별할 수 없습니다. 디스코드에서 다시 인증 버튼을 눌러 접근해주세요.')
      }, 500)
      return
    }
  }

  const balanceQuery = async () => {
    if (!klaytn || !klaytn.selectedAddress) return

    if (klaytn.networkVersion !== 8217) {
      setVerifyEnable(false)
      setVerifyButtonText('❌ 메인넷 아님 ❌')
      return
    }

    const caver = new Caver(klaytn)

    let totalBalance = 0

    await Promise.all(ContractAddresses.map(async (address) => {
      const contract = caver.contract.create(BalanceABI, address)
      const balance = await contract.call('balanceOf', klaytn.selectedAddress)
      totalBalance += Number.parseInt(balance)
    }))

    if (totalBalance > 0) {
      setVerifyEnable(true)
      setVerifyButtonText(`인증하기 (보유 ${totalBalance})`)
    } else {
      setVerifyButtonText('❗️현재 홀더가 아닙니다')
    }
  }

  useEffect(() => {
    if (!klaytn?.isKaikas || !klaytn?.selectedAddress)
      return

    balanceQuery().finally()

  }, [ connectButtonText ])

  useEffect(() => {
    if (!klaytn?.isKaikas || !klaytn?.selectedAddress)
      return

    kaikasActivated().finally()
  }, [])

  return (
    <div className="App">
      <video muted playsInline={true} autoPlay={ true } loop={ true } poster={'/dd-package.png'}>
        <source src={'/dd-package.mp4'} type={'video/mp4'} />
        Your browser does not support the video tag.
      </video>
      <div className='wrapper'>
        <div style={ { height: '5%' } }>
        </div>
        <div style={ { maxWidth: '80%' } }>
          <button
            className={ connectClassNames }
            disabled={ !connectEnabled }
            onClick={ activateKaikas }>
            <img
              width={45}
              height={35}
              style={ { paddingRight: '10px' } }
              src={ '/klay_white.svg' }
              alt={ 'klaytn icon' }/>
            <span style={ { overflow: 'hidden', textOverflow: 'ellipsis' } }>
              { connectButtonText }
            </span>

          </button>
        </div>
        <div style={ { height: '25%' } }>

        </div>
        <div style={ { maxWidth: '80%' } }>
          <button
            className={ 'verify-button' }
            disabled={ !verifyEnabled }
            onClick={ verify }>
            { verifyButtonText }
          </button>
        </div>
      </div>
    </div>
  );
}

export default App;
