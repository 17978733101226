import { AbiItem } from 'caver-js';

export const BalanceABI: AbiItem[] = [ {
  "name": "balanceOf",
  "inputs": [
    {
      "internalType": "address",
      "name": "owner",
      "type": "address"
    }
  ],
  "outputs": [
    {
      "internalType": "uint256",
      "name": "",
      "type": "uint256"
    }
  ],
  "stateMutability": "view",
  "type": "function"
} ]

export const ContractAddresses = [
  '0x03f3F6EeFf2A01B5927714B8e48fc24663f6414f',
  '0x2a7eb9f9277a32190dc40da7f96514517174c8f3'
]
